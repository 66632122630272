import React from 'react'

function Page404() {
  return (
<div className='container'>
    <div className='row justify-content-center'>
        <div className='col-md-6'>
            <div className='card card-body'>
                <h1>Page404 | Url/Page Not found</h1>
            </div>

        </div>

    </div>
    
</div>
  )
}

export default Page404