import React from 'react'
import MasterLayout from '../../layouts/admin/MasterLayout';
import '../admin/admin.css';


const Profile = () => {
 
  return (
    <>
    <MasterLayout/>
    <div className='home'>PRofile</div>
    </>
  )
}

export default Profile;