import React from 'react'
import '../admin/admin.css';
import MasterLayout from '../../layouts/admin/MasterLayout';

const Dashboard = () => {

  return (
    <>
     <MasterLayout/>
    <div className='home'>Dashboard</div>
    </>
  
  )
}

export default Dashboard;